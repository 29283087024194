import { useCallback, useState } from 'react';
import { IRow, Notification } from 'react-ui-kit-exante';

import { FORCE_CLOSE_EVENT_STATE } from 'constants/common';
import { forceCloseEventService } from 'resources';
import { TForceCloseEvent } from 'types';
import { sendUiKitErrorNotification } from 'utils';

type TUseBulkActionsProps = {
  refetch: () => Promise<void>;
};

type TSelectedRows = {
  autoExecute: boolean;
  number: string;
  state: string;
};

export const useBulkActions = ({ refetch }: TUseBulkActionsProps) => {
  const [selectedRows, setSelectedRows] = useState<TSelectedRows[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const hasRows = selectedRows.length > 0;

  const handleSelectRow = useCallback(
    (row: IRow<TForceCloseEvent> | IRow<TForceCloseEvent>[]) => {
      setSelectedRows((prev: TSelectedRows[]): TSelectedRows[] => {
        if (Array.isArray(row)) {
          return row.map((rowItem) => {
            const {
              original: { autoExecute, number, state },
            } = rowItem;

            return {
              autoExecute: Boolean(autoExecute),
              number,
              state,
            };
          });
        }

        const {
          original: { autoExecute, number, state },
        } = row;

        const foundIndex = prev.findIndex((item) => item.number === number);

        if (foundIndex > -1) {
          return prev.filter((_, index) => index !== foundIndex);
        }

        return [...prev, { autoExecute: Boolean(autoExecute), number, state }];
      });
    },
    [setSelectedRows],
  );

  const handleAutoLiquidate = useCallback(() => {
    setModalOpened(true);
  }, [selectedRows]);

  const handleModalClose = () => {
    setModalOpened(false);
  };

  const handleAutoLiquidateConfirm = async () => {
    try {
      let showWarning = false;

      Promise.all(
        selectedRows
          .filter((item) => {
            if (
              item.state !== FORCE_CLOSE_EVENT_STATE.close &&
              item.autoExecute === false
            ) {
              return true;
            }

            showWarning = true;

            return false;
          })
          .map((item) =>
            forceCloseEventService.updateForceCloseEvent(item.number, {
              autoExecute: true,
            }),
          ),
      )
        .then((values) => {
          if (values.length) {
            Notification.success({
              title: 'Force Close Event(s) successfully auto liquidated',
            });
          }

          if (showWarning) {
            Notification.warning({
              title:
                `Some event(s) were on state close or auto liquidation enabled, ` +
                `because of these reasons they haven't been send to the auto liquidation`,
            });
          }
        })
        .catch((err) => {
          sendUiKitErrorNotification(err);
        })
        .finally(() => {
          refetch();
        });
    } catch (err) {
      sendUiKitErrorNotification(err);
    } finally {
      setModalOpened(false);
      setSelectedRows([]);
    }
  };

  return {
    handleAutoLiquidate,
    handleAutoLiquidateConfirm,
    handleModalClose,
    handleSelectRow,
    hasRows,
    modalOpened,
    selectedRows,
    setSelectedRows,
  };
};
