import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { initResources } from './resources';
import { ComposedThemeProvider } from './theme';
import { addBaseTag } from './utils';

addBaseTag();
initResources();

window.REMUS_UI = {
  ...(window.REMUS_UI || {}),
  render: (node: Element) => {
    if (!node) {
      throw new Error('Root element was not found');
    }

    const root = createRoot(node);

    root.render(
      <React.StrictMode>
        <ComposedThemeProvider>
          <App />
        </ComposedThemeProvider>
      </React.StrictMode>,
    );
  },
};

const remusUiDiv = document.getElementById('remus-ui') as HTMLElement;

if (!remusUiDiv) {
  throw new Error('Root element was not found');
}

const root = createRoot(remusUiDiv);

root.render(
  <React.StrictMode>
    <ComposedThemeProvider>
      <App />
    </ComposedThemeProvider>
  </React.StrictMode>,
);
