import { format as _format } from 'date-fns';

import { DATE_WITH_TIME_FORMAT, DATE_FORMAT } from '../../consts';
import { getBrowserLocale } from '../../helpers';

type TFormatDateParams = {
  date: string;
  withTime?: boolean;
  dateUTC?: boolean;
  dateTimeUTC?: boolean;
  useLocale?: boolean;
  format?: string;
  toUTCDate?: (date: Date) => Date;
};

export const formatDate = ({
  date,
  withTime = false,
  dateUTC = false,
  dateTimeUTC = false,
  useLocale = false,
  format = withTime || dateTimeUTC ? DATE_WITH_TIME_FORMAT : DATE_FORMAT,
  toUTCDate,
}: TFormatDateParams) => {
  const dateWrapper = new Date(date);
  const isValidDate = !Number.isNaN(dateWrapper.getDate());

  if (!date) {
    return '–';
  }

  if (!isValidDate) {
    return date;
  }

  const browserLocale = getBrowserLocale();
  const shouldFormatToUTC = dateUTC || dateTimeUTC;

  if (shouldFormatToUTC) {
    const utcDate = toUTCDate(dateWrapper);

    if (useLocale) {
      return dateTimeUTC
        ? dateWrapper.toLocaleString(browserLocale, { timeZone: 'UTC' })
        : dateWrapper.toLocaleDateString(browserLocale, { timeZone: 'UTC' });
    }

    return _format(utcDate, format);
  }

  if (useLocale) {
    return withTime
      ? dateWrapper.toLocaleString(browserLocale)
      : dateWrapper.toLocaleDateString(browserLocale);
  }

  return _format(dateWrapper, format);
};
