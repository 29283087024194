import { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Button, Modal } from 'react-ui-kit-exante';

import { POSTPONE_OPTIONS } from 'pages/RiskEvent/constants';
import { RiskEventContext } from 'pages/RiskEvent/context';

import {
  ControlsInputMultiline,
  FormActions,
  ToggleButtonGroupContainer,
} from '../styled';

import { CONTROL_CLS, Resolutions, ResolutionsOptions } from './constants';
import { useResolutionForm } from './hooks';
import { TControllerRenderProps, TResolutionProps } from './types';

export const Resolution = ({ refetch }: TResolutionProps) => {
  const { state, dispatch } = useContext(RiskEventContext);
  const [disableShiftImmunity, setDisableShiftImmunity] = useState(false);

  const { control, errors, isValid, handleModalClose, handleSubmit, onSubmit } =
    useResolutionForm({
      dispatch,
      refetch,
      state,
    });

  const handleResolutionChange = (
    value: Resolutions,
    field: TControllerRenderProps,
  ) => {
    switch (value) {
      case Resolutions.Negative:
        setDisableShiftImmunity(true);
        break;
      default:
        setDisableShiftImmunity(false);
    }

    field.onChange(value);
  };

  return (
    <Modal
      cancelButton={{
        hidden: true,
      }}
      isOpened={state.resolutionModal.show}
      onClose={handleModalClose}
      title="Resolution"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="resolution"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroupContainer
              exclusive
              onChange={(value: string) =>
                handleResolutionChange(value as Resolutions, field)
              }
              options={ResolutionsOptions}
              value={field.value}
              error={Boolean(errors.resolution)}
              message={errors.resolution?.message}
            />
          )}
        />
        <Controller
          name="shiftImmunity"
          control={control}
          render={({ field }) => (
            <Autocomplete
              controlProps={{
                placeholder: 'Postpone',
              }}
              disabled={disableShiftImmunity}
              onChange={(_e, value) => field.onChange(value)}
              options={POSTPONE_OPTIONS}
              sx={CONTROL_CLS}
              value={field.value}
            />
          )}
        />
        <Controller
          name="internalComment"
          control={control}
          render={({ field }) => (
            <ControlsInputMultiline
              {...field}
              label="Comment"
              multiline
              rows={4}
              value={field.value}
              error={Boolean(errors.internalComment)}
              message={errors.internalComment?.message}
            />
          )}
        />
        <FormActions>
          <Button color="secondary" onClick={handleModalClose} type="button">
            Close
          </Button>
          <Button disabled={!isValid} type="submit">
            Confirm
          </Button>
        </FormActions>
      </form>
    </Modal>
  );
};
